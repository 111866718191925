<template>
  <div id="app">
    <Navbar class="sticky-top"></Navbar>
    <router-view class="main" />
    <Recruit></Recruit>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import Recruit from "@/components/Recruit.vue";
export default {
  components: {
    Navbar,
    Footer,
    Recruit,
  },
};
</script>
<style lang="scss">
@import "@/assets/all.scss";

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.main {
  flex: 1;
}
body {
  background-color: #151517 !important;
  color: #737373 !important;
}
</style>
