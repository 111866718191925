<template>
  <div>
    <nav class="py-3 navbar navbar-expand-lg navbar-dark bg-dark">
      <div class="container-fluid">
        <router-link class=" mx-0 navbar-brand logo" to="/"></router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto text-center">
            <li class="nav-item">
              <router-link
                class="px-4 nav-link link"
                aria-current="page"
                to="/about"
                >ABOUT</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                class="px-4 nav-link link"
                aria-current="page"
                to="/projects"
                >PORTFOLIO</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                class="px-4 nav-link link"
                aria-current="page"
                to="/team"
                >TEAM</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                class="px-4 nav-link link"
                aria-current="page"
                to="/contact"
                >CONTACT</router-link
              >
            </li>
          </ul>
        </div>
        <div class="d-none d-lg-block  navbar-brand logo logo-right"></div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // token: localStorage.getItem("token") || "",
    };
  },
  methods: {
    admin() {
      console.log(this.token);
      this.axios
        .post("http://127.0.0.1/test.php", {
          token: this.token,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.status) {
            this.$router.push("/admin").catch((err) => {});
          } else {
            this.$router.push("/login").catch((err) => {});
          }
        });
    },
    logout() {
      localStorage.removeItem("token");
      this.$store.dispatch("SET_TOKEN", "");
      this.$router.push("/").catch((err) => {});
    },
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
};
</script>
<style scoped>
.logo {
  background-image: url("http://cfd.com.tw/images/logo.svg");
  width: 80px;
  height: 27px;
  background-size: cover;
}
.logo-right {
  opacity: 0;
}
.link {
  font-size: 16px;
}
</style>
